var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-main',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('div',{staticStyle:{"text-align":"center","margin-bottom":"18px"}}),_c('v-card',{staticClass:"elevation-12 pa-8"},[_c('v-card-text',[_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"d-block text-center"},[_c('img',{staticClass:"logo-forms mr-auto my-auto",attrs:{"src":require("../assets/logo.svg"),"alt":""}})]),_c('div',{staticClass:"mt-6 mb-8 text-center"},[_c('p',[_vm._v("Vstupte do moderního domu jednadvacátého století.")])])]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}}},[(_vm.processing)?_c('Spinner'):_vm._e(),_c('v-text-field',{ref:"email",staticClass:"mymd-primary-dark-text",attrs:{"label":"e-mail","name":"login","type":"text"},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", $$v)},expression:"$v.email.$model"}}),_c('span',{staticClass:"error-input",class:{
                    'error-input--active': _vm.$v.email.$anyError,
                  }},[_vm._v(" "+_vm._s(!_vm.$v.email.required ? 'Toto pole je povinné' : 'Neplatný email')+" ")]),_c('v-text-field',{attrs:{"id":"password","label":"Heslo","name":"password","type":"password"},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", $$v)},expression:"$v.password.$model"}}),_c('span',{staticClass:"error-input",class:{
                    'error-input--active': _vm.$v.password.$anyError,
                  }},[_vm._v(" "+_vm._s(!_vm.$v.password.required ? 'Toto pole je povinné' : '')+" ")])],1)],1),_c('v-card-actions',[_c('v-btn',{staticStyle:{"margin":"0 auto","width":"100%"},attrs:{"tile":"","type":"submit","color":"primary"},on:{"click":_vm.login}},[_vm._v("Přihlásit se")])],1),_c('div',{staticClass:"mt-8",staticStyle:{"text-align":"center","width":"100%","display":"block"}},[_c('button',{staticClass:"mymd-primary-dark-text",staticStyle:{"font-size":"12px","z-index":"999","width":"100%"},attrs:{"type":"button"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () {}).apply(null, arguments)},"click":function($event){$event.stopPropagation();return (function () { return _vm.$router
                      .push({
                        name: 'ForgottenPassword',
                      })
                      .catch(function () {}); }).apply(null, arguments)}}},[_vm._v(" Zapomněli jste heslo ? ")])])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{staticClass:"rounded-0",attrs:{"max-width":450,"persistent":""},model:{value:(_vm.policiesDialog),callback:function ($$v) {_vm.policiesDialog=$$v},expression:"policiesDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Obchodní podmínky")]),_c('v-card-text',[_c('span',[_vm._v("Pro pokračování je potřeba odsouhlasit "),_c('a',{staticStyle:{"font-weight":"bolder"},attrs:{"href":"/vop","target":"_blank"}},[_vm._v("obchodní podmínky")])]),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","small":"","tile":"","width":"100%"},on:{"click":_vm.acceptPolicies}},[_vm._v("Souhlasím")])],1)],1)],1),_c('v-card-actions')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }