import req from "./request";

export default {
  getUsers: async (
    page = 1,
    limit = 25,
    orderBy = "id",
    descending = "desc",
    search = null,
    filter = null
  ) => {
    try {
      let url = `users?page=${page}&limit=${limit}&orderBy=${orderBy}&descending=${
        descending ? "desc" : "asc"
      }`;
      if (search) url += `&search=${search}`;
      if (filter) url += `&filter=${filter}`;
      const { data } = await req.get(url, true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getRoles: async () => {
    try {
      const { data } = await req.get("users/roles", true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getUser: async (userId) => {
    try {
      const { data } = await req.get(`users/${userId}`, true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  createUser: async (data) => {
    try {
      const response = await req.post(`users`, data, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateUser: async (userId, data) => {
    try {
      const response = await req.put(`users/${userId}`, data, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateSelf: async (data) => {
    try {
      const response = await req.put(`auth/self-update`, data, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteUser: async (userId) => {
    try {
      return await req.delete(`users/${userId}`, true);
    } catch (error) {
      throw error;
    }
  },
  exportUsers: async () => {
    try {
      return await req.axios.get(`${req.apiUrl}/users/export`, {
        headers: { ...req.headers(true) },
      });
    } catch (error) {
      throw error;
    }
  },
};
