<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <div class="" style="text-align: center; margin-bottom: 18px"></div>
            <v-card class="elevation-12 pa-8">
              <v-card-text>
                <div class="d-block">
                  <div class="d-block text-center">
                    <img
                      src="../assets/logo.svg"
                      alt=""
                      class="logo-forms mr-auto my-auto"
                    />
                  </div>
                  <div class="mt-6 mb-8 text-center">
                    <p>Vstupte do moderního domu jednadvacátého století.</p>
                  </div>
                </div>
                <v-form @submit.prevent="login" @keyup.native.enter="login">
                  <Spinner v-if="processing" />
                  <v-text-field
                    ref="email"
                    label="e-mail"
                    name="login"
                    type="text"
                    class="mymd-primary-dark-text"
                    v-model="$v.email.$model"
                  ></v-text-field>
                  <span
                    class="error-input"
                    :class="{
                      'error-input--active': $v.email.$anyError,
                    }"
                  >
                    {{
                      !$v.email.required
                        ? 'Toto pole je povinné'
                        : 'Neplatný email'
                    }}
                  </span>
                  <v-text-field
                    id="password"
                    label="Heslo"
                    name="password"
                    type="password"
                    v-model="$v.password.$model"
                  ></v-text-field>
                  <span
                    class="error-input"
                    :class="{
                      'error-input--active': $v.password.$anyError,
                    }"
                  >
                    {{ !$v.password.required ? 'Toto pole je povinné' : '' }}
                  </span>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  tile
                  type="submit"
                  color="primary"
                  style="margin: 0 auto; width: 100%"
                  @click="login"
                  >Přihlásit se</v-btn
                >
              </v-card-actions>
              <div
                style="text-align: center; width: 100%; display: block"
                class="mt-8"
              >
                <button
                  type="button"
                  @keydown.enter.prevent="() => {}"
                  @click.stop="
                    () =>
                      $router
                        .push({
                          name: 'ForgottenPassword',
                        })
                        .catch(() => {})
                  "
                  style="font-size: 12px; z-index: 999; width: 100%"
                  class="mymd-primary-dark-text"
                >
                  Zapomněli jste heslo ?
                </button>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-dialog
            v-model="policiesDialog"
            :max-width="450"
            persistent
            class="rounded-0"
          >
            <v-card>
              <v-card-title class="headline">Obchodní podmínky</v-card-title>

              <v-card-text>
                <span
                  >Pro pokračování je potřeba odsouhlasit
                  <a style="font-weight: bolder" href="/vop" target="_blank"
                    >obchodní podmínky</a
                  ></span
                >

                <v-row class="mt-6">
                  <v-col class="text-center">
                    <v-btn
                      @click="acceptPolicies"
                      color="primary"
                      small
                      tile
                      width="100%"
                      >Souhlasím</v-btn
                    >
                    <!-- :disabled="selectedRoles.length ? false : true" -->
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
/* eslint-disable */
import { validationMixin } from 'vuelidate';
import { required, maxLength, email } from 'vuelidate/lib/validators';
import Auth from '../api/authService';
import userService from '../api/userService';
import Spinner from '../components/Spinner';
import bus from '../plugins/bus';
export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
    password: { required },
  },
  name: 'Login',
  components: {
    Spinner,
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Must be valid e-mail');
      !this.$v.email.required && errors.push('E-mail is required');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push('Password is required');
      return errors;
    },
  },
  data() {
    return {
      policiesDialog: false,
      email: null,
      password: null,
      processing: false,
      user: null,
      loginFailed: {
        message: '',
        res: false,
      },
    };
  },
  async created() {
    try {
      if (this.$store) {
        // await this.$store.dispatch("getCurrentUser");
        // console.log("AUTHENTICATED");
        // this.$router.push({ name: "Dashboard" });
      }
    } catch (error) {
      console.log('UNAUTHORIZED', error);
    }
  },
  methods: {
    async acceptPolicies() {
      try {
        bus.$emit('processing');
        const data = this.user;
        data.policies = true;
        await userService.updateSelf(data);
        bus.$emit('processing', false);
        this.$router.push({ name: 'Dashboard' });
      } catch (error) {
        console.error(error);
      }
    },
    async login() {
      this.$data.loginFailed = {
        message: '',
        res: false,
      };

      this.loggingProgress = true;
      this.$v.$touch();
      const data = {
        email: this.$data.email,
        password: this.$data.password,
      };
      if (!this.passwordErrors.length && !this.emailErrors.length) {
        this.$data.loginFailed = {};
        console.debug('LOGIN USER');
        bus.$emit('processing');
        Auth.login(data)
          .then(async (res) => {
            console.debug(res);
            this.$data.loginFailed.res = false;
            this.$data.loggingProgress = false;
            bus.$emit('processing', false);
            await this.$store.dispatch('setUser', res);
            const user = this.$store.state.user;
            if (user.policies) {
              this.$router.push({ name: 'Dashboard' });
            } else {
              this.user = user;
              // Handle policies
              this.policiesDialog = true;
            }
          })
          .catch((err) => {
            bus.$emit('processing', false);
            this.$data.loggingProgress = false;
            bus.$emit('snackbar', { text: 'Nepřihlášený', color: 'error' });
          });
      } else {
        this.$data.loginFailed = {
          message: 'Please fill in the form ..',
          res: true,
        };
        this.$data.loggingProgress = false;
      }
    },
  },
};
</script>
